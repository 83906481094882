<template>
  <CRow class="mb-5">
    <CCol lg="3">
      <TAside>
        <CListGroup>
          <CListGroupItem>
            <FilterForOwningBoxes :queries.sync="query" />
          </CListGroupItem>
          <CListGroupItem
            v-for="(box, index) in owning_boxes"
            :key="`${index}${box.id}`"
            :color="owning_box_id_selected == box.id ? 'primary' : 'secondary'"
            :to="_.getAppendRouteQuery($route, { owning_box_id: box.id })"
          >
            <SCardOwningBox :owning_box="box" />
          </CListGroupItem>
          <CListGroupItem v-if="!owning_boxes.length">
            <TMessageNotFound />
          </CListGroupItem>
          <CListGroupItem v-if="owning_boxes_loading">
            <TSpinner :loading="owning_boxes_loading" />
          </CListGroupItem>
          <SButtonLoadmore store="warehouse.owning_boxes" />
        </CListGroup>
      </TAside>
    </CCol>
    <CCol lg="9">
      <CRow v-if="owning_box_id_selected">
        <CCol col="12">
          <CCard>
            <CCardHeader>
              <div class="d-flex align-items-center">
                <TMessage content="Distribution" bold>
                  <template #suffix>
                    {{ owning_box_id_selected }}
                  </template>
                </TMessage>
                <CBadge
                  v-if="box_selected.is_cancel_distributed"
                  color="danger"
                  class="ml-1"
                >
                  <TMessage content="Distributed canceled" bold />
                </CBadge>
                <div class="ml-auto d-flex align-items-center">
                  <TButton
                    v-if="!box_selected.is_cancel_distributed"
                    @click="cancelDistribution"
                    content="Cancel a distribution"
                    variant=""
                    icon="cil-x-circle"
                    color="warning"
                    :options="{ disabled: cancel_distribution_loading }"
                  />
                  <TButtonDelete
                    @click="removeOwningBox"
                    class="ml-1"
                    variant=""
                    :options="{ disabled: detail_loading }"
                  />
                  <SButtonNextToDistribution
                    class="ml-1"
                    :query="`order_id=${box_selected.order_id}`"
                  />
                </div>
              </div>
            </CCardHeader>
            <CCardBody>
              <TTableAsForm
                :fields="boxFields"
                :data="box_selected"
                :splitLeft="5"
                title="Info"
                :addRowClasses="['my-2']"
              >
                <template #updated_at="{ value }">
                  <TMessageDateTime :content="value" />
                </template>
                <template #order_id="{ value }">
                  <TLink
                    v-if="value"
                    :content="value"
                    :messageOptions="{ bold: true }"
                    :to="lodash.getReferenceLink('assemble-to-order', value)"
                  />
                </template>
                <template #user="{ value }">
                  <TLink
                    v-if="value"
                    :content="value.name"
                    :messageOptions="{ bold: true }"
                    :to="lodash.getReferenceLink('user', value.id)"
                  />
                </template>
                <template #box_id="{ value }">
                  <TLink
                    v-if="value"
                    :content="value"
                    :messageOptions="{ bold: true }"
                    :to="lodash.getReferenceLink('box', value)"
                  />
                </template>
                <template #quantity="{ value }">
                  <CBadge color="info" style="font-size: 85%">
                    <TMessageNumber :value="value" />
                  </CBadge>
                </template>
              </TTableAsForm>
              <TTableAsForm
                title="Requests"
                :data="box_selected"
                :fields="requestFields"
                class="mt-4"
                :splitLeft="5"
              >
                <template #repackage="{ value }">
                  <TSwitch :checked="value" :disabled="owning_boxes_loading" />
                </template>
                <template #merge_package="{ value }">
                  <TSwitch :checked="value" :disabled="owning_boxes_loading" />
                </template>
              </TTableAsForm>
            </CCardBody>
          </CCard>
          <CCard>
            <CCardHeader>
              <TMessage content="Costs" bold />
            </CCardHeader>
            <CCardBody>
              <TTableAsForm
                :fields="costFields"
                :data="box_selected"
                :splitLeft="5"
              >
                <template #additional_cost="{ value }">
                  <TMessageMoney
                    :amount="value"
                    :currency="box_selected.currency_id || 'VND'"
                    editable
                    @change="updateOwningBox('additional_cost', $event)"
                  />
                </template>
                <template #compensation_amount="{ value }">
                  <TMessageMoney
                    :amount="value"
                    :currency="box_selected.currency_id || 'VND'"
                  />
                </template>
                <template #storage_cost="{ value }">
                  <TMessageMoney
                    :amount="value"
                    :currency="box_selected.currency_id || 'VND'"
                    editable
                    @change="updateOwningBox('storage_cost', $event)"
                  />
                </template>
              </TTableAsForm>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </CCol>
  </CRow>
</template>

<script>
import { mapGetters } from "vuex";
import FilterForOwningBoxes from "../../components/FilterForOwningBoxes.vue";
import filterable from "../../mixins/filterable";
export default {
  components: { FilterForOwningBoxes },
  mixins: [filterable],
  data() {
    return {
      boxFields: [
        { key: "order_id", label: "Order" },
        { key: "user", label: "Customer" },
        { key: "box_id", label: "Box" },
        { key: "quantity", label: "Quantity" },
        { key: "updated_at", label: "Updated at" },
      ],
      costFields: [
        { key: "additional_cost", label: "Additional cost" },
        { key: "storage_cost", label: "Storage cost" },
        { key: "compensation_amount", label: "Commodity compensation" },
      ],
      requestFields: [
        { key: "repackage", label: "Repackage" },
        { key: "merge_package", label: "Merge package" },
      ],
    };
  },
  mounted() {
    if (this.lodash.isEmpty(this.query)) {
      this.$store.dispatch("warehouse.owning_boxes.fetch.if-first-time");
    } else {
      this.$store.dispatch("warehouse.owning_boxes.apply-query", this.query);
    }
  },
  watch: {
    owning_box_id_selected: {
      immediate: true,
      handler(id) {
        if (id) {
          this.$store.dispatch("warehouse.owning_boxes.detail.fetch", id);
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      owning_boxes: "warehouse.owning_boxes.list",
      owning_boxes_loading: "warehouse.owning_boxes.loading",
      detail_loading: "warehouse.owning_boxes.detail.loading",
      cancel_distribution_loading:
        "warehouse.cancel_distributed.detail.loading",
    }),
    owning_box_id_selected() {
      return this.$route.query.owning_box_id;
    },
    box_selected() {
      return this.$store.getters["warehouse.owning_boxes.detail"];
    },
  },
  methods: {
    fetchOwningBox() {
      this.$store.dispatch("warehouse.owning_boxes.detail.refresh");
    },
    updateOwningBox(field, value) {
      this.$store.dispatch("warehouse.owning_boxes.detail.update", {
        [field]: value,
      });
    },
    removeOwningBox() {
      this.$store.dispatch("warehouse.owning_boxes.detail.delete").then(() => {
        this.$router.push(`/warehouse/internal/owning-boxes/distributed`);
      });
    },
    cancelDistribution() {
      this.$store
        .dispatch(
          "warehouse.cancel_distributed.detail.cancel",
          this.owning_box_id_selected
        )
        .then(() => {
          this.fetchOwningBox();
        });
    },
  },
};
</script>
